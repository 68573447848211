* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* src/App.css */

@font-face {
    font-family: 'RiftonNorm';
    src: url('./Assets/fonts/rifton-norm.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body,
html {
    /* font-family: "Roboto", sans-serif; */
    font-family: "Inter", sans-serif;

}

input {
    outline: none !important;
}

.text-white {
    color: #fff
}

.text-black {
    color: #000
}

a {
    text-decoration: none;
}


::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    background: #252525;
    border-radius: 6px;
    ;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;

}

.create-post-modal {
    border-radius: 15px;
    background-color: #000000;
    padding-bottom: 0;

    :where(.css-dev-only-do-not-override-1xg9z9n).ant-upload-wrapper .ant-upload-drag {
        border: none !important;
    }

    .ant-modal-content {
        .ant-modal-footer {
            display: none;
        }

        border-radius: 15px !important;
        box-shadow: none !important;
        overflow: hidden !important;
        padding: 0 !important;
        background-color: #000000 !important;
    }

    .ant-modal-footer {
        text-align: center !important;
    }
}

textarea {
    outline: none;
}

.slick-list {
    border-bottom: solid transparent !important;
}

.fanxo-dropdown {
    ul {
        background-color: #000000 !important;
        border: 1px solid white;
        padding: 0 !important;

        li {
            color: white !important;
            border-bottom: 1px solid white;
            border-radius: 0 !important;
            padding: 5px 24px !important;
            text-align: center;
        }

        li:last-child {
            border: none !important;
        }
    }
}

.fanxo-modal {
    .ant-modal-content {
        background-color: black !important;
        padding: 0px;
        border: 1px solid #ffffff80;
        overflow: hidden;
        padding-top: 1.2rem;
    }
}

.select-text-only-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 135px);
    width: calc(100% - 2rem) !important;
}