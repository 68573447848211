@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-noto {
  font-family: 'Noto Sans', sans-serif;
}

.font-light {
  font-weight: 300;
}

.font-reg {
  font-weight: 400;
}

.font-med {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

button, [type='button']{
  background-color: #1677ff;
  color: #ffffff;
}